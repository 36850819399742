import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "content-switcher",
      "style": {
        "position": "relative"
      }
    }}>{`Content Switcher`}</h1>
    <p>{`O `}<em parentName="p">{`Content Switch`}</em>{` manipula o conteúdo apresentado na tela alternando entre as opções apresentadas através de botões, e por `}<em parentName="p">{`default`}</em>{` sempre tem uma opção selecionada. Utilize o `}<em parentName="p">{`content switch`}</em>{` em telas onde as opções são mutuamente exclusivas, permitindo que o usuário selecione apenas uma opção.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Considere utilizar as boas práticas de `}<a parentName="li" {...{
          "href": "/components/button"
        }}>{`Botões`}</a>{` para as labels.`}</li>
      <li parentName="ul">{`Sempre há uma opção selecionada por padrão.`}</li>
    </ul>
    <Demo src='pages/components/content-switch/ContentSwitch' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      